// ========= REACT =========
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// ========= UTILITIES =========
import { getScores, listenForScores } from "../app/firebase";
import { ordinalSuffix } from "../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuid from "uuid/v4";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// ========= TOKENS =========
import COLORS from "@deeplocal/colors/google";

// ========= STYLE =========
import "normalize.css";
import "../styles/main.css";
import "../styles/typography.css";
import "../styles/sizing.css";

// ========= STYLED COMPONENTS =========
const ScreenShare = styled.video`
	position: absolute;
	left: -7.8125vw;
	width: 100vw;
	height: 100vh;
`;

const ScoreBoard = styled.div`
	position: absolute;
	width: 15.625vw;
	height: 100vh;
	right: 0;
	top: 0;
	font-weight: normal;
	background-color: ${COLORS.defaults.yellow};
	filter: drop-shadow(0px 5px 5px ${COLORS.grey[`900`]}22);
`;

const Headline = styled.h2`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 8%;
	font-size: 2.75em;
	color: white;
	font-weight: bold;
	text-align: center;
`;

const PlayerContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;

	width: 100%;
	height: 5%;

	color: ${(props) => {
		switch (props.order) {
			case 1:
				return COLORS.defaults.blue;
			case 2:
				return COLORS.defaults.yellow;
			case 3:
				return COLORS.defaults.green;
			default:
				return COLORS.defaults.grey;
		}
	}};

	background: ${(props) =>
		props.order % 2 === 0 ? COLORS.grey[`100`] : COLORS.grey[`50`]};

	&.scores-enter {
		height: 0;
		background-color: ${COLORS.defaults.red};
		color: white;
		overflow: hidden;
	}
	&.scores-enter-active {
		height: 5%;
		transition: height ease 500ms;
	}
	&.scores-enter-done {
		background: ${(props) =>
		props.order % 2 === 0
			? COLORS.grey[`100`]
			: COLORS.grey[`50`]};

		color: ${(props) => {
		switch (props.order) {
			case 1:
				return COLORS.defaults.blue;
			case 2:
				return COLORS.defaults.yellow;
			case 3:
				return COLORS.defaults.green;
			default:
				return COLORS.defaults.grey;
		}
	}};
		transition: background-color ease 500ms 1s, color ease 500ms 1s;
	}

	&.scores-exit {
		height: 5%;
	}
	&.scores-exit-active {
		height: 0;
		transition: height 500ms;
		overflow: hidden;
	}
`;

const Ordinal = styled.div`

	align-self: center;
	
	width: 30%;
	height: calc(var(--u) * 8);
	line-height: calc(var(--u) * 8);
	text-align: center;

	font-size: 1.5em;

	border-radius: calc(var(--radius) / 3);

	font-weight: bold;
	
	${(props) =>
		props.order <= 3 &&
		`filter: drop-shadow(0px 5px 5px \${COLORS.grey[\`900\`]}22);`}

	${(props) => {
		switch (props.order) {
			case 1:
			case 3:
				return `transform: rotate(-5deg);`;
			case 2:
				return `transform: rotate(5deg);`;
			default:
				return ``;
		}
	}}

	color: ${(props) => (props.order <= 3 ? `white` : `inherit`)};
	background: ${(props) => {
		switch (props.order) {
			case 1:
				return COLORS.defaults.blue;
			case 2:
				return COLORS.defaults.yellow;
			case 3:
				return COLORS.defaults.green;
			default:
				return `inherit`;
		}
	}};
`;

const Name = styled.div`
	width: 30%;

	font-size: 2em;
	font-weight: bold;
	text-align: center;

	span {
		display: inline-block;
		width: 1.2em;
	}
`;

const PlayerScore = styled.div`
	width: 20%;

	font-size: 1em;
	font-weight: bold;

	span {
		display: inline-block;
		width: 0.65em;
		text-align: center;
	}
`;

const Player = ({ order, name, score, id }) => (
	<PlayerContainer key={id} order={order}>
		<Ordinal order={order}>{ordinalSuffix(order)}</Ordinal>
		<Name>{name}</Name>
		<PlayerScore>
			{/* if the score is a number, make it monospaced */}
			{/* if the score is a loading spinner, display it */}
			{typeof score === `number`
				? `${score}`
					.split(``)
					.map((num, i) => <span key={i}>{num}</span>)
				: score}
		</PlayerScore>
	</PlayerContainer>
);

// ========= MAIN =========
const billboard = () => {
	// get webcam
	useEffect(() => {
		(async () => {
			if (navigator.mediaDevices.getUserMedia) {
				const stream = await navigator.mediaDevices.getUserMedia(
					{ video: true }
				);
				document.querySelector(
					`#screenshare`
				).srcObject = stream;
			}
		})();
	}, []);

	// update scores as they change
	const [scores, setScores] = useState([]);
	const [lastScores, setLastScores] = useState([]);
	useEffect(() => {
		const NUM_SCORES = 18;
		const spinner = {
			name: (
				<FontAwesomeIcon icon="spinner" className="fa-pulse" />
			),
			score: (
				<FontAwesomeIcon icon="spinner" className="fa-pulse" />
			),
		};

		// set temp scores while real scores are loading
		if (scores.length === 0) {
			const tempScores = [];
			for (let i = 0; i < NUM_SCORES; i++) {
				tempScores.push({ ...spinner, id: `SPINNER-${i}` });
			}
			setScores(scores);
		}

		// listen for updates from firebase
		listenForScores(NUM_SCORES, (result) => {
			if (result.length < NUM_SCORES) {
				for (let i = result.length; i < NUM_SCORES; i++) {
					result.push({ ...spinner, id: `SPINNER-${i}` });
				}
			}

			setLastScores(scores);
			setScores(result);
		});
	}, []);

	return (
		<>
			<ScreenShare autoPlay id="screenshare" />
			<ScoreBoard>
				<Headline>High Scores</Headline>

				<TransitionGroup component={null}>
					{scores.map((player, i) =>
						typeof player === `undefined` ? (
							<Player
								name=""
								score=""
								order={i + 1}
								key={uuid()}
							/>
						) : (
								<CSSTransition
									in={
										typeof lastScores.find(
											(lastScore) => {
												return (
													lastScore.id ===
													player.id
												);
											}
										) === `undefined`
									}
									key={player.id}
									timeout={500}
									classNames="scores"
								>
									<Player
										name={player.name}
										score={player.score}
										order={i + 1}
										key={player.id}
									/>
								</CSSTransition>
							)
					)}
				</TransitionGroup>
			</ScoreBoard>
		</>
	);
};

export default billboard;
